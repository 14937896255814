import { Routes, RouterModule } from '@angular/router';
import { PostsComponent } from './posts/posts.component';
import { PaginaPrincipalComponent } from './pagina-principal/pagina-principal.component';
import { PostsPaginaComponent } from './posts-pagina/posts-pagina.component';
import { PaginaSobremimComponent } from './pagina-sobremim/pagina-sobremim.component';
import { PaginaOqfacoComponent } from './pagina-oqfaco/pagina-oqfaco.component';
import { ContatoComponent } from './contato/contato.component';

const routes: Routes = [
    {
      path: 'blog',
      component: PostsComponent
    },
    {
      path: '**',
      redirectTo: 'not-found'
    },
    {
      path: '',
      pathMatch: 'full',
      component: PaginaPrincipalComponent
    },
    {
      path: 'sobre',
      component: PaginaSobremimComponent
    },
    {
      path: 'skill',
      component: PaginaOqfacoComponent
    },
    {
      path: 'contato',
      component: ContatoComponent
    },
    // abaixo caminho das paginas dos posts
    {
      path: 'blog/post/1',
      component: PostsPaginaComponent
    },
    {
      path: 'blog/post/2',
      component: PostsPaginaComponent
    },
    {
      path: 'blog/post/3',
      component: PostsPaginaComponent
    },
];
export const routing = RouterModule.forRoot(routes);
