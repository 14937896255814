import { Component, OnInit } from '@angular/core';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [animationsSelect]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    const twitter = document.getElementById('twitter');
    const linkedin = document.getElementById('linkedin');
    const github = document.getElementById('github');
    const wrapFooter = document.getElementById('wrapFooter');
    const footMain = document.getElementById('foot-main');

    $(footMain).hide();
    setTimeout(() => {
       $(footMain).show( 'slow' );
     }, 2000);
    $(twitter).on('mouseover', () => {
      twitter.style.color = '#fff';
      linkedin.style.color = '#333';
      github.style.color = '#333';
    });
    $(linkedin).on('mouseover', () => {
      twitter.style.color = '#333';
      linkedin.style.color = '#fff';
      github.style.color = '#333';
    });
    $(github).on('mouseover', () => {
      twitter.style.color = '#333';
      linkedin.style.color = '#333';
      github.style.color = '#fff';
    });
    $(wrapFooter).on('mouseout', () => {
      twitter.style.color = '#fff';
      linkedin.style.color = '#fff';
      github.style.color = '#fff';
    });
  }

}
