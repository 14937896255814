import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-pagina-principal',
  templateUrl: './pagina-principal.component.html',
  styleUrls: ['./pagina-principal.component.css'],
  animations: [animationsSelect]
})
export class PaginaPrincipalComponent implements OnInit {

  constructor(private titleService: Title) { this.titleService.setTitle('Gabriel Fellone | DEV'); }
  logo = '</>';
  ngOnInit() {
    const div = document.getElementById('primeiro');
    const div2 = document.getElementById('segundo');
    const div3 = document.getElementById('terceiro');
    const textoInicio = 'EU FAÇO &';
    const texto = 'EU FAÇO';
    const texto2 = 'COMPARTILHO';
    const texto3 = 'DIZ SOBRE MIM';
    let fullTittle = 1;
    let typer;

    escrever(textoInicio, div, fullTittle);

    const primeiro = document.getElementById('primeiro');
    const segundo = document.getElementById('segundo');
    const terceiro = document.getElementById('terceiro');
    $(primeiro).on('mouseover', () => {
      fullTittle = 4;
      clearInterval(typer);
      primeiro.style.color = '#fff';
      segundo.style.color = '#333';
      terceiro.style.color = '#333';
      $( primeiro ).text(texto + '?');
      $( segundo ).text(texto2);
      $( terceiro ).text(texto3);
    });
    $(segundo).on('mouseover', () => {
      fullTittle = 4;
      clearInterval(typer);
      primeiro.style.color = '#333';
      segundo.style.color = '#fff';
      terceiro.style.color = '#333';
      $( primeiro ).text(texto);
      $( segundo ).text(texto2 + '?');
      $( terceiro ).text(texto3);
    });
    $(terceiro).on('mouseover', () => {
      fullTittle = 4;
      clearInterval(typer);
      primeiro.style.color = '#333';
      segundo.style.color = '#333';
      terceiro.style.color = '#fff';
      $( primeiro ).text(texto);
      $( segundo ).text(texto2);
      $( terceiro ).text(texto3 + '?');
    });

    function tempoOver() {
      setTimeout(() => {
        $('#conteudo').on('mouseout', () => {
          fullTittle = 4;
          clearInterval(typer);
          primeiro.style.color = '#fff';
          segundo.style.color = '#fff';
          terceiro.style.color = '#fff';
          $( primeiro ).text(texto + '&');
          $( segundo ).text(texto2);
          $( terceiro ).text(texto3);
        });
      }, 2000);
    }

    function escrever(str, el, tittle) {
      if (tittle <= 3) {
        const char = str.split('').reverse();
        typer = setInterval(() => {
        if (!char.length) {  clearInterval(typer); nextWord(); }
        const next = char.pop();
        const texto1 = $( el ).text();
        if ( undefined !== next || null != next ) {
          $( el ).text(texto1 + next);
        }}, 100);
      }
      tempoOver();
    }

    function nextWord() {
      if (fullTittle === 1) {
       fullTittle ++;
       escrever(texto2, div2, fullTittle);
      } else {
        fullTittle ++;
        escrever(texto3, div3, fullTittle);
      }
    }
  }
}
