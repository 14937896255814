import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { PostsPaginaInterface } from '../posts-pagina-interface';
import { PostServiceService } from '../post-service.service';
import { Title } from '@angular/platform-browser';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-posts-pagina',
  templateUrl: './posts-pagina.component.html',
  styleUrls: ['./posts-pagina.component.css'],
  animations: [animationsSelect]
})

export class PostsPaginaComponent implements OnInit {
  public postPagina: Array<PostsPaginaInterface> = [];
  count: [];
  valoresPagina = [];
  linkPagina = 'link';
  LinkUrlPagina = location.pathname.substring(11);

  constructor(private postServiceService: PostServiceService,
              private titleService: Title) { this.titleService.setTitle('BLOG | Gabriel Fellone'); }


  procuraDadosPagina(pagina) {
    this.postServiceService.capturarPosts(pagina).subscribe(
      (response) => {
        this.montaPostPagina(response as any);
      });
  }
  montaPostPagina(event: HttpResponse<any>) {
    if (event) {
      this.postPagina = [];
      this.valoresPagina = [];
      // tslint:disable-next-line: forin
      for (const key in event) {
       this.count = (event[key]);
       this.valoresPagina.push(this.count);
      }
      this.postPagina.push(
       {titulo: this.valoresPagina[11], // titulo
       nomeAutor: this.valoresPagina[9], // nome do autor
       conteudo: this.valoresPagina[0], // counteudo da pagina
       data: this.valoresPagina[1],  // data da publicacao
       imagem: this.valoresPagina[4], // imagem do post
       subtitulo: this.valoresPagina[10], // subtitulo
       fotoAutor: this.valoresPagina[3], // foto do autor
       descricaoAutor: this.valoresPagina[2], // descricao do autor
       linkGitHub: this.valoresPagina[6], // link do github autor
       linkLinkedin: this.valoresPagina[7], // link do linkedin autor
       linkTwitter: this.valoresPagina[8] // link do twitter autor
    });
    } else {
      window.location.replace('/blog');
    }
    localStorage.clear();
  }
  armazenaPagina(pagina) {
    localStorage.setItem(this.linkPagina, pagina);
  }
  ngOnInit() {
    this.verificaUrl();
  }
  verificaUrl() {
    if (localStorage.getItem(this.linkPagina) === '0'
    || localStorage.getItem(this.linkPagina) === undefined
    || localStorage.getItem(this.linkPagina) === null) {
      return this.procuraDadosPagina(this.LinkUrlPagina);
    } else {
      return this.procuraDadosPagina(localStorage.getItem(this.linkPagina));
    }
  }
}
