import { Component, OnInit, Injectable, Input } from '@angular/core';
import { PostServiceService } from '../post-service.service';
import { HttpResponse } from '@angular/common/http';
import { PostsInterface } from '../posts-interface';
import { PostsPaginaComponent } from '../posts-pagina/posts-pagina.component';
import { Title } from '@angular/platform-browser';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css'],
  animations: [animationsSelect]
})
@Injectable()
export class PostsComponent implements OnInit {
  public postConteudo: Array<PostsInterface> = [];
  postPaginaClass = new PostsPaginaComponent(this.postServiceService, this.titleService);
  posts: any;
  count: [];
  valoresPost = [];
  totalPaginas = 0;


  constructor(private postServiceService: PostServiceService,
              private titleService: Title) { this.titleService.setTitle('BLOG | Gabriel Fellone'); }

  ngOnInit() {
  this.procuraPost(1);
  const loading = document.getElementById('loading');
  const container = document.getElementById('container');
  setTimeout(() => {
    loading.style.display = 'none';
    $('#container').fadeIn('slow');
    container.style.display = 'block';
    }, 2000);
  }

  procuraPost(pagina: number) {
    this.postServiceService.capturarPosts(pagina).subscribe(
      (response) => {
        this.quantidadePosts(response as any);
      });
  }

  quantidadePosts(event: HttpResponse<any>) {
    if (event) {
      this.totalPaginas ++;
      this.procuraPost(this.totalPaginas);
    } else {
      this.totalPaginas --;
      this.postDecrecente(this.totalPaginas);
    }
  }

  postDecrecente(pagina: number) {
    this.postServiceService.capturarPosts(pagina).subscribe(
      (response) => {
        console.log(response),
        this.montaPost(response as any);
      });
  }

  montaPost(event: HttpResponse<any>) {
    if (event) {
      this.valoresPost = [];
      // tslint:disable-next-line: forin
      for (const key in event) {
      this.count = (event[key]);
      this.valoresPost.push(this.count);
      console.log(this.valoresPost);
      }
      this.postConteudo.push(
        {titulo: this.valoresPost[11], // titulo
        subtitulo: this.valoresPost[10], // subtitulo
        link: this.valoresPost[5], // link para puxar o conteudo na api
        imagem: this.valoresPost[4], // link da imagem
        data: this.valoresPost[1]}); // data do post
      this.totalPaginas --;
      this.postDecrecente(this.totalPaginas);
    } else {
      localStorage.clear();
    }
  }
  topodaPagina() {
    window.scrollTo(0, 0);
  }
}
