import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PostsComponent } from './posts/posts.component';
import { PostServiceService } from './post-service.service';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './navbar/navbar.component';
import { PostsPaginaComponent } from './posts-pagina/posts-pagina.component';
import { routing } from './app.router';
import { PaginaPrincipalComponent } from './pagina-principal/pagina-principal.component';
import { PaginaSobremimComponent } from './pagina-sobremim/pagina-sobremim.component';
import { PaginaOqfacoComponent } from './pagina-oqfaco/pagina-oqfaco.component';
import { FooterComponent } from './footer/footer.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ContatoComponent } from './contato/contato.component';

@NgModule({
  declarations: [
    AppComponent,
    PostsComponent,
    NavbarComponent,
    PostsPaginaComponent,
    PaginaPrincipalComponent,
    PaginaSobremimComponent,
    PaginaOqfacoComponent,
    FooterComponent,
    ContatoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    routing,
    AngularFontAwesomeModule
  ],
  providers: [PostServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
