import { Component, OnInit } from '@angular/core';
import { animationsSelect } from '../animations';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css'],
  animations: [animationsSelect]
})
export class ContatoComponent implements OnInit {

  constructor(private titleService: Title) { this.titleService.setTitle('CONTACT | Gabriel Fellone'); }

  ngOnInit() {
    const twitter = document.getElementById('twitter');
    const linkedin = document.getElementById('linkedin');
    const github = document.getElementById('github');
    const email = document.getElementById('email');
    const wrapFooter = document.getElementById('wrapFooter');

    const twitterspan = document.getElementById('twitterspan');
    const linkedinspan = document.getElementById('linkedinspan');
    const githubspan = document.getElementById('githubspan');
    const emailspan = document.getElementById('emailspan');

    $(twitter).on('mouseover', () => {
      twitterspan.style.display = 'block';
      linkedinspan.style.display = 'none';
      githubspan.style.display = 'none';
      emailspan.style.display = 'none';
    });
    $(linkedin).on('mouseover', () => {
      twitterspan.style.display = 'none';
      linkedinspan.style.display = 'block';
      githubspan.style.display = 'none';
      emailspan.style.display = 'none';
    });
    $(github).on('mouseover', () => {
      twitterspan.style.display = 'none';
      linkedinspan.style.display = 'none';
      githubspan.style.display = 'block';
      emailspan.style.display = 'none';
    });
    $(email).on('mouseover', () => {
      twitterspan.style.display = 'none';
      linkedinspan.style.display = 'none';
      githubspan.style.display = 'none';
      emailspan.style.display = 'block';
    });
    $(wrapFooter).on('mouseout', () => {
      twitterspan.style.display = 'none';
      linkedinspan.style.display = 'none';
      githubspan.style.display = 'none';
      emailspan.style.display = 'none';
    });
  }

}
