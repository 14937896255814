import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostServiceService {

  constructor(private httpClient: HttpClient) { }

  capturarPosts(pagina: number) {
    return this.httpClient.get('https://blog-412ff.firebaseio.com/posts/' + pagina + '.json');
  }
}
