import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  logo = '<Gabriel Fellone/>'; // Nome logo Superior

  constructor(public router: Router) { }

  ngOnInit() {
    const slideWrp = '.side-menu-wrapper'; // Menu Wrapper
    const openButton = '.menu-open'; // Menu Open Button
    const closeButton	= '.menu-close'; // Menu Close Button
    const overlay = '.menu-overlay'; // Overlay

    // MENU
    $(slideWrp).hide().css( {left: -$(slideWrp).outerWidth() + 'px'}).delay(50).queue(() => {$(slideWrp).show(); });

    $(openButton).on('click', (e) => { // Menu ao clicar
     e.preventDefault();
     $(slideWrp).css( {left: '0px'}); // Move menu para esquerda totalmente
     setTimeout(() => {
    $(slideWrp).addClass('active'); // Add active class
    }, 50);
     $(overlay).css({opacity: '1', width: '100%'});
    });

    $(closeButton).on('click', (e) => { // Fechar Menu
      e.preventDefault();
      $(slideWrp).css( {left: -$(slideWrp).outerWidth() + 'px'}); // Esconde o Menu na esquerda
      setTimeout(() => {
        $(slideWrp).removeClass('active'); // Remove active class
      }, 50);
      $(overlay).css({opacity: '0', width: '0'});
  });
    $(document).on('click', (e) => { // Esconde Menu ao clicar fora da area
      if ($(slideWrp).hasClass('active')) { // Verifica condicao do Menu
       $(slideWrp).css( {left: -$(slideWrp).outerWidth() + 'px'}).removeClass('active');
       $(overlay).css({opacity: '0', width: '0'});
      }
  });
 }
}
