import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-pagina-sobremim',
  templateUrl: './pagina-sobremim.component.html',
  styleUrls: ['./pagina-sobremim.component.css'],
  animations: [animationsSelect]
})
export class PaginaSobremimComponent implements OnInit {

  constructor(private titleService: Title) { this.titleService.setTitle('ABOUT | Gabriel Fellone'); }

  ngOnInit() {
    const loading = document.getElementById('loading');
    const container = document.getElementById('container');
    setTimeout(() => {
    loading.style.display = 'none';
    $('#container').fadeIn('slow');
    container.style.display = 'block';
    }, 2000);
 }
}
