import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { animationsSelect } from '../animations';

@Component({
  selector: 'app-pagina-oqfaco',
  templateUrl: './pagina-oqfaco.component.html',
  styleUrls: ['./pagina-oqfaco.component.css'],
  animations: [animationsSelect]
})
export class PaginaOqfacoComponent implements OnInit {

  constructor(private titleService: Title) { this.titleService.setTitle('SKILL | Gabriel Fellone'); }

  ngOnInit() {

    $( '.row ul li.html div span' ).on('mouseover', () => {
      $( '.row ul li.html div span' ).text( 'AWESOME!' );
    });
    $( '.row ul li.css div span' ).on('mouseover', () => {
      $( '.row ul li.css div span' ).text( 'GOOD' );
    });
    $( '.row ul li.angular div span' ).on('mouseover', () => {
      $( '.row ul li.angular div span' ).text( 'GREAT!' );
    });
    $( '.row ul li.java div span' ).on('mouseover', () => {
      $( '.row ul li.java div span' ).text( 'YEAH!' );
    });
    $( '.row ul li.javascript div span' ).on('mouseover', () => {
      $( '.row ul li.javascript div span' ).text( 'UNICORN' );
    });
    $( '.row ul li.git div span' ).on('mouseover', () => {
      $( '.row ul li.git div span' ).text( 'LOVE IT' );
    });
    $( '.row ul li.ux div span' ).on('mouseover', () => {
      $( '.row ul li.ux div span' ).text( 'ROCK ON' );
    });
    $( '.row ul li.unix div span' ).on('mouseover', () => {
      $( '.row ul li.unix div span' ).text( '4EVER' );
    });

    // --------------------- MOUSE OUT

    $( '.row ul li.html div span' ).on('mouseout', () => {
      $( '.row ul li.html div span' ).text( '' );
    });
    $( '.row ul li.css div span' ).on('mouseout', () => {
      $( '.row ul li.css div span' ).text( '' );
    });
    $( '.row ul li.angular div span' ).on('mouseout', () => {
      $( '.row ul li.angular div span' ).text( '' );
    });
    $( '.row ul li.java div span' ).on('mouseout', () => {
      $( '.row ul li.java div span' ).text( '' );
    });
    $( '.row ul li.javascript div span' ).on('mouseout', () => {
      $( '.row ul li.javascript div span' ).text( '' );
    });
    $( '.row ul li.git div span' ).on('mouseout', () => {
      $( '.row ul li.git div span' ).text( '' );
    });
    $( '.row ul li.ux div span' ).on('mouseout', () => {
      $( '.row ul li.ux div span' ).text( '' );
    });
    $( '.row ul li.unix div span' ).on('mouseout', () => {
      $( '.row ul li.unix div span' ).text( '' );
    });

  }

  /* *
 * CREDIT: Skills CSS Design and Creatd by Siamon Hasan
 * http://siamonh.com
   ================================================== */
}
