import { trigger, transition, style, animate, state } from '@angular/animations';
export const animationsSelect =  trigger('simpleFadeAnimation', [
  transition(':enter', [
    style({opacity: 0}),
    animate('1s')
  ]),
  transition(':leave', [
    style({opacity: 0}),
    animate('1s')
  ]),
]);
